





















































































































































import { strict as assert } from "assert";

import { Component, Vue, Watch } from "vue-property-decorator";
import { gql } from "apollo-boost";
import providers from "@providers/provider-apps";
import RelativeTime from "@app/components/relative-time.vue";
import VehicleCharts from "@app/components/vehicle-charts.vue";
import VehicleActions from "@app/components/vehicle-actions.vue";
import { geoDistance } from "@shared/utils";
import apollo from "@app/plugins/apollo";
import { VueApolloComponentOptions } from "vue-apollo/types/options";
import { RawLocation } from "vue-router";
import moment from "moment";
import config from "@shared/smartcharge-config";
import { makePublicID } from "@shared/utils";
import { GQLVehicle } from "@shared/sc-schema";
import { getVehicleLocationSettings } from "@shared/sc-utils";
import { vehicleFragment, GQLLocationFragment } from "@shared/sc-client";

@Component({
  components: { VehicleActions, RelativeTime, VehicleCharts },
  apollo: {
    vehicle: {
      query: gql`
        query GetVehicle($id: String!) {
          vehicle(id: $id) { ${vehicleFragment} }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      fetchPolicy: "cache-and-network",
      pollInterval: 5 * 60e3, // poll at least every 5 minutes
      subscribeToMore: {
        document: gql`subscription VehicleSubscription($id:String!) { vehicleSubscription(id: $id) { ${vehicleFragment} } }`,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        fetchPolicy: "cache-and-network",
        // Mutate the previous result
        updateQuery: (previousResult: any, { subscriptionData }: any) => {
          return {
            vehicle: {
              ...((previousResult && previousResult.vehicle) || undefined),
              ...subscriptionData.data.vehicleSubscription,
            },
          };
        },
        skip() {
          return this.locations === undefined;
        },
      },

      update(data) {
        // TODO REMOVE
        /*
        if (data.vehicle && data.vehicle.pausedUntil) {
          const when = new Date(data.vehicle.pausedUntil).getTime();
          const now = Date.now();
          if (when <= now) {
            data.vehicle.pausedUntil = null;
          }
        }
        // TODO REMOVE
        if (data.vehicle && data.vehicle.tripSchedule) {
          const when = new Date(data.vehicle.tripSchedule.time).getTime();
          const now = Date.now();
          if (when + 3600e3 <= now) {
            data.vehicle.tripSchedule = null;
          }
        }*/
        this.updateFreshness(data.vehicle);
        return data.vehicle;
      },
      watchLoading(isLoading, _countModifier) {
        this.loading = isLoading;
      },
      skip() {
        return this.locations === undefined;
      },
    },
  } as VueApolloComponentOptions<VehicleVue>, // needed because skip is not declared on subscribeToMore, but I am pretty sure I had to have it in my tests when the query had toggled skip()
})
export default class VehicleVue extends Vue {
  loading!: boolean;
  vehicle?: GQLVehicle;
  location?: GQLLocationFragment;
  locations!: GQLLocationFragment[];
  prettyStatus!: string;
  freshInfo!: boolean;

  data() {
    return {
      loading: false,
      vehicle: undefined,
      location: undefined,
      locations: undefined,
      prettyStatus: "",
      freshInfo: false,
    };
  }

  updateFreshness(vehicle: GQLVehicle | undefined) {
    this.freshInfo = Boolean(
      vehicle && Date.now() - new Date(vehicle.updated).getTime() < 300e3
    ); // five minutes
  }

  timer?: any;
  async created() {
    this.locations = await apollo.getLocations();
    this.$apollo.queries.vehicle.skip = false;

    this.timer = setInterval(() => {
      // TODO: remove?
      this.updateFreshness(this.vehicle);
    }, 30e3);
  }

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  get vehiclePictureUnknown() {
    return (
      config.SINGLE_USER === "false" &&
      this.vehicle &&
      this.vehicle.providerData &&
      this.vehicle.providerData.unknown_image
    );
  }

  get vehiclePictureReportURL() {
    if (this.vehicle !== undefined) {
      const publicID = makePublicID(this.vehicle.id);
      return `https://github.com/fredli74/smartcharge-dev/issues/new?assignees=&labels=enhancement&template=incorrect-image.md&title=Wrong+vehicle+image+for+${publicID}`;
    }
    return "";
  }

  get vehiclePicture() {
    if (this.vehicle !== undefined) {
      /*if (this.vehiclePictureUnknown) {
        return require("../assets/unknown_vehicle.png");
      } else*/ {
        const provider = providers.find(
          (p) => p.name === this.vehicle!.providerData.provider
        );
        if (provider && provider.image) {
          return provider.image(this.vehicle);
        }
      }
    }
    return "";
  }
  get vehicleConnectedAtUnknownLocation(): boolean {
    return (
      this.vehicle !== undefined &&
      this.vehicle.isConnected &&
      this.vehicle.geoLocation !== null &&
      this.vehicle.locationID === null
    );
  }
  get addLocationURL(): RawLocation {
    assert(this.vehicle !== undefined);
    assert(this.vehicle.geoLocation !== null);
    return {
      path: "/add/location",
      query: {
        lat: this.vehicle.geoLocation.latitude.toString(),
        long: this.vehicle.geoLocation.longitude.toString(),
      },
    };
  }

  @Watch("vehicle", { immediate: true, deep: true })
  onVehicleUpdate(val: GQLVehicle, _oldVal: GQLVehicle) {
    if (!val) return;
    let prefix = "";
    let suffix = "";

    this.location = undefined;

    if (val.isConnected && !val.chargingTo) {
      prefix = "Connected and";
    }

    if (val.locationID && this.locations) {
      this.location = this.locations.find((f) => f.id === val.locationID);
      assert(this.location !== undefined);

      suffix = `${val.isDriving ? "near" : "@"} ${this.location.name}`;
    }

    if (
      !this.location &&
      !val.isConnected &&
      val.geoLocation &&
      this.locations
    ) {
      let closest = Number.POSITIVE_INFINITY;
      for (const l of this.locations) {
        if (l.ownerID !== val.ownerID) continue;

        const dist =
          geoDistance(
            val.geoLocation.latitude,
            val.geoLocation.longitude,
            l.geoLocation.latitude,
            l.geoLocation.longitude
          ) / 1e3;
        if (dist < closest) {
          closest = dist;
          this.location = l;
        }
      }
      if (this.location) {
        if (closest < 1) {
          suffix = "near";
        } else {
          suffix = Number(closest.toFixed(1)).toString() + " km from";
        }
        suffix += " " + this.location.name;
      }
    }

    this.prettyStatus =
      (prefix ? prefix + " " + val.status.toLowerCase() : val.status) +
      (suffix ? " " + suffix : "");
  }
  get batteryColor() {
    assert(this.vehicle !== undefined);
    const settings = getVehicleLocationSettings(this.vehicle);
    return this.vehicle.batteryLevel > this.vehicle.maximumLevel
      ? "#9cef19"
      : this.vehicle.batteryLevel > settings.directLevel
      ? "#4cd853"
      : this.vehicle.batteryLevel > 10
      ? "orange"
      : "red";
  }
  get nochargestyle() {
    assert(this.vehicle !== undefined);
    const width =
      100 - Math.max(this.vehicle.chargingTo || 0, this.vehicle.maximumLevel);
    if (width > 0) {
      return `width:${width}%`;
    } else {
      return `display:none`;
    }
  }

  replaceISOtime(s: string): string {
    return s.replace(
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g,
      (f) => moment(f).format("YYYY-MM-DD HH:mm")
    );
  }
}
